<template>
    <div>
      <!-- Start Header Area -->
      <Header>
        <img slot="logo" :src="logo" />
      </Header>
      <!-- End Header Area -->
  
      <!-- Start Breadcrumb Area  -->
      <div class="rn-page-title-area pt--120 pb--190 bg_image bg_image--1">
        <v-container>
          <v-row>
            <v-col cols="12">
              <div class="text-center rn-page-title pt--100">
                <h2 class="heading-title theme-gradient">
                  Learning Experience Design Statement Updated
                </h2>
                <p>Module 7 Assignment</p>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <!-- End Breadcrumb Area  -->
  
      <!-- Start Portfolio Details Area  -->
      <div class="rn-portfolio-details ptb--120 bg_color--1">
        <v-container>
          <v-row>
            <v-col>
              <div class="portfolio-details">
                <div class="row row--35 mb--50">
                  <div class="col-lg-12">
                    <div class="inner">
                      <div class="section-title">
                        <h2 class="heading-title">Learning Experience Design Statement</h2>
                        <div
                          class="description mt--30"
                          style="text-align: justify !important"
                        >
                          <p>
                            As a lifelong learner and educator, my approach to learning experience design is deeply influenced by my personal and professional journey. My passion for learning began at a young age when I discovered the joy of understanding how things work by taking them apart and putting them back together. This curiosity-driven approach has shaped not only how I learn but also how I teach. My experiences in the Army, where I quickly learned to adapt and appreciate the importance of life and professional skills, further solidified my belief in the power of learning to transform lives.
                          </p>
                          <p>In my role as an instructor at Weber State University, where I teach Computer Science and Web and User Experience, I am constantly reminded of the importance of creating learning experiences that are not only informative but also engaging and relevant to the real world. I helped develop the Web and User Experience program because I recognized a need for a curriculum that balanced technical skills with design principles. This initiative was driven by my commitment to student-centered learning and my belief that education should be adaptable to meet the diverse needs of learners.</p>
                          <h4>Philosophy and Beliefs</h4>
                          <p>
                            My philosophy of learning experience design is anchored in the belief that education should be practical, engaging, and aligned with the needs of the students. I am a strong advocate for hands-on learning, where students can apply what they have learned in real-world contexts. This approach is particularly important in the field of web programming, where the ability to solve real-world problems is critical. I believe that learning experiences should mirror the challenges students will face in their careers, preparing them to navigate complexity and ambiguity with confidence.
                          </p>
                          <p><span style="background-color: yellow;">A core component of my philosophy that has evolved is an emphasis on iterative feedback. Reflecting on the significance of feedback in learning, I have become more intentional about integrating formative assessments and real-time feedback in my design. I now see feedback as essential not only for correcting misunderstandings but also for deepening learning and promoting student agency. This insight has inspired me to incorporate more reflective activities where students self-assess their learning journey, building self-awareness as they develop technical skills.</span></p>
                          <p>One of the core principles I adhere to in my design process is the integration of accessibility. As a visual learner myself, I understand the importance of presenting information in a way that is easy to comprehend and apply. In my courses, I emphasize the need for accessibility not only in the design of digital products but also in the delivery of course content. This commitment to accessibility ensures that all students, regardless of their learning style or background, have an equal opportunity to succeed.</p>
                          <p><span style="background-color: yellow;">Additionally, I have gained a deeper understanding of universal design principles from our readings this semester, which has reinforced my focus on creating inclusive learning experiences. I now prioritize designing with multiple forms of engagement, such as multimedia resources, to address diverse learner preferences. This adaptation goes beyond accessibility; it enhances overall engagement and retention by recognizing each student’s unique way of interacting with content.</span></p>
                          <h4>Professional Growth and Influence</h4>
                          <p>
                            My journey as an educator has been one of continuous growth and adaptation. I fell into teaching without formal experience in education, but with a deep understanding of my subject matter. This practical experience has been invaluable in shaping my teaching methods. However, recognizing the need for formal training in instructional design, I embarked on a program in Learning, Design, and Technology (LDT) to enhance my skills. This program has opened my eyes to the many theories and practices that underpin effective learning experience design, many of which I had unknowingly been applying in my classes.
                          </p>
                          <p>Through this program, I have come to appreciate the importance of intentional design in creating learning experiences that are both effective and engaging. The insights gained from the LDT program are already influencing how I approach course development. I am particularly excited about incorporating more design thinking into my courses, which will allow me to create learning experiences that are not only well-structured but also innovative and adaptable to the needs of my students.</p>
                          <p><span style="background-color: yellow;">An area of growth that has been particularly transformative is my understanding of scaffolding and how it supports students in mastering complex concepts. I now implement gradual skill-building exercises that lead students from simpler tasks to more advanced applications. This approach aligns with Vygotsky’s Zone of Proximal Development theory, which I encountered in my LDT readings. Integrating these scaffolds has not only increased my students' engagement but has also empowered them to tackle challenges with greater confidence and autonomy.</span></p>
                          <h4>Future Outlook</h4>
                          <p>
                            Looking ahead, I am committed to continuously refining my approach to learning experience design. As technology evolves, so too must our educational practices. My goal is to stay at the forefront of these changes, ensuring that the learning experiences I design are relevant, accessible, and impactful. I am particularly interested in exploring how emerging technologies, such as artificial intelligence and virtual reality, can be integrated into the classroom to enhance learning outcomes.
                          </p>
                          <p><span style="background-color: yellow;">From the readings and discussions this semester, I am increasingly inspired to incorporate adaptive learning technologies that personalize content for students based on their progress. I believe AI-driven tools can be a powerful addition to my courses, enabling me to offer differentiated instruction that caters to each learner’s pace and style. This integration would provide insights into students' learning patterns, allowing me to make data-informed adjustments to instructional strategies, ensuring that every student achieves the desired learning outcomes.</span></p>
                          <p>In conclusion, my learning experience design philosophy is a reflection of my lifelong passion for learning and teaching. It is informed by my personal experiences, professional journey, and the insights I have gained from formal education in instructional design. As an educator, my ultimate goal is to create learning experiences that empower students to succeed in a rapidly changing world, equipping them with the skills and knowledge they need to thrive in their careers and beyond.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <!-- End Portfolio Details Area  -->
  
      <Footer />
    </div>
  </template>
  
  <script>
  import Header from "../../components/header/HeaderThree";
  import Footer from "../../components/footer/FooterTwo";
  import feather from "feather-icons";
  
  export default {
    components: {
      Header,
      Footer,
    },
    data() {
      return {
        logo: require("../../assets/images/logo/logo.png"),
      };
    },
    methods: {
      iconSvg(icon) {
        return feather.icons[icon].toSvg();
      },
    },
  };
  </script>
